import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../components/CustomButton/CustomButton';
import { COLOR } from '../utils/color';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '100vh', flexDirection: 'column', mx: 2, }}>

      <Box sx={{ textAlign: 'center', mb: 2, display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h1'>404</Typography>
        <Typography variant='h6'>Oops! Page Not Found</Typography>
        <Typography variant='caption' color={COLOR.neutral600}>Link mungkin rusak atau halaman nya telah di hapus!</Typography>
      </Box>
      {location.key !== 'default' && <CustomButton sx={{ mt: 2, width: 'fit-content' }} onClick={() => {
        if (location.key !== 'default') {
          navigate(-1);
        }
      }}>
        <Typography>Kembali</Typography>
      </CustomButton>}


      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt={5}
      >
        <Typography variant="caption">Powered By</Typography>
        <Box height="24px" display="flex" justifyContent="center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/neema-b7c3b.appspot.com/o/public-web-assets%2FLogo.png?alt=media&token=3ddd51e9-0144-40a6-a29e-6e23f91cda8c"
            alt="neema-logo"
            className="img-default"
            style={{ width: "fit-content" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;