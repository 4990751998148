import ENV from "../utils/env";

export const BASE_URL = ENV.BASE_URL;

export const endpoints = {
  BASE_URL,
  REGISTER: `${BASE_URL}/register`,
  LOGIN: `${BASE_URL}/login`,
  LOGIN_GOOGLE: `${BASE_URL}/google`,
  REFRESH_TOKEN: `${BASE_URL}/refresh-token`,
  LOGOUT: `${BASE_URL}/logout`,
  COMPANY: `${BASE_URL}/company`,
  PRODUCT_RESERVATION: `${BASE_URL}/productReservation`,
  PRODUCT_LINK: `${BASE_URL}/productLink`,
  PRODUCT_SECTION: `${BASE_URL}/productSection`,
  PRODUCT: `${BASE_URL}/product`,
  TRANSACTION: `${BASE_URL}/transaction`,
  PRODUCT_STORAGE_UPLOAD: `${BASE_URL}/storage/upload/product`,
  STORAGE_DOWNLOAD: `${BASE_URL}/storage/download`,
  COMPANY_LOGO: `${BASE_URL}/storage/upload/companyLogo`,
  COMPANY_BY_DOMAIN: `${BASE_URL}/company/public`,
  CHECK_DOMAIN_AVAILABILITY: `${BASE_URL}/company/check`,
  GET_PUBLIC_PRODUCT_BY_ID: `${BASE_URL}/product/public`,
  GET_PUBLIC_PRODUCT_RESERVATION_BY_ID: `${BASE_URL}/productReservation/public`,
  RESCHEDULE: `${BASE_URL}/productReservation/public/reschedule`,
  GET_PUBLIC_PRODUCT_DETAIL: `${BASE_URL}/product/public/`,
  GET_PUBLIC_PRODUCT_RESERVATIONDETAIL: `${BASE_URL}/productReservation/public/`,
  PUBLIC_CALCULATE_TRANSACTION: `${BASE_URL}/transaction/calculate`,
  GET_SCHEDULE_DETAILS_BY_PRODUCT_ID: `${BASE_URL}/product/`,
  GET_SCHEDULE_DETAILS_BY_PRODUCT_ID_V2: `${BASE_URL}/productReservation`,
  GET_PRODUCT_COMPACT_LIST: `${BASE_URL}/product/compact`,
  PUBLIC_CREATE_NEW_TRANSACTION: `${BASE_URL}/transaction/`,
  PUBLIC_REFUND_TRANSACTION: `${BASE_URL}/transaction/refund`,
  PUBLIC_CANCEL_TRANSACTION: `${BASE_URL}/transaction/cancel`,
  PUBLIC_CONFIRM_TRANSACTION: `${BASE_URL}/transaction/confirm`,
  PUBLIC_GET_TRANSACTION_BY_ID: `${BASE_URL}/transaction/public`,
  STORAGE_UPLOAD: `${BASE_URL}/storage/upload/v2`,
  COMPANY_V2: `${BASE_URL}/company/v2`,
  PUBLIC_COMPANY_V2: `${BASE_URL}/company/public/v2`,
  GET_PRODUCT_RESERVATION_COMPACT_LIST: `${BASE_URL}/productReservation/compact`,
  PUBLIC_GET_PRODUCT_RESERVATION: `${BASE_URL}/productReservation/public`,
  REQUEST_FORGOT_PASSWORD: `${BASE_URL}/send-forgot-password`,
  VERIFY_EMAIL: `${BASE_URL}/verify-email`,
  RESEND_VERIFICATION_EMAIL: `${BASE_URL}/resend-verification`,
  DOWNLOAD_TRANSACTION_CSV: `${BASE_URL}/transaction/download-csv`,
  RESET_PASSWORD: `${BASE_URL}/reset-password `,
  CHANGE_PASSWORD: `${BASE_URL}/change-password `,
  TOTAL_TRANSACTION_PER_DATE: `${BASE_URL}/dashboard/transaction-by-date `,
  TOTAL_TRANSACTION_BY_STATUS: `${BASE_URL}/dashboard/transaction-status `,
  PRODUCT_BY_TRANSACTION: `${BASE_URL}/dashboard/product-by-transaction `,
  TRANSACTION_OVERVIEW: `${BASE_URL}/dashboard/transaction-overview `,
  UPCOMING_SCHEDULES: `${BASE_URL}/dashboard/upcoming-schedules `,
  COMPANY_ACCOUNT: `${BASE_URL}/companyAccount`,
  WITHDRAW: `${BASE_URL}/withdraw`,
};

export default endpoints;
