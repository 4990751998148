import { Theme } from '@emotion/react';
import { SxProps, Typography } from '@mui/material';
import React from 'react';

export interface StatusTextPropsI {
  children: React.ReactNode;
  color: string;
  backgroundColor: string;
  sx?: SxProps<Theme>;
}
const StatusText = (props: StatusTextPropsI) => {
  return (
    <Typography sx={{
      width: "fit-content",
      px: "10px",
      py: "4px",
      backgroundColor: props.backgroundColor,
      color: props.color,
      fontSize: "10px",
      fontWeight: 600,
      textTransform: "capitalize",
      borderRadius: "24px",
      ...props?.sx,
    }}>
      {props.children}
    </Typography>
  )

}
export default StatusText;