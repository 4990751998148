import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography, SxProps, Skeleton } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import { COLOR } from "../../../utils/color";
import {
  DEFAULT_SNACKBAR_PROPS,
  FONT_COLOR,
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
} from "../../../utils/constant";
import { Theme } from "@emotion/react";
import {
  useGetCheckoutDetailById,
  useGetPublicProductScheduleByIdV2,
  useGetPublicTransactionById,
} from "../../../query/queries";
import {
  GetPublicTransactionByIdResponse,
  RescheduleProductRequest,
  ScheduleDetailWithQty,
} from "../../../api/request.types";
import dayjs from "dayjs";
import {
  PAYMENT_STATUS,
  PublicScheduleDetailWithQty,
} from "../../../types/globalTypes";
import TemplateButton from "../../../components/TemplateButton/TemplateButton";
import useNavigateDynamicPage from "../../../hooks/useNavigateDynamicPage";
import { formatCurrency } from "../../../utils/helper";
import CalendarPicker from "../../../components/CalendarPIcker/CalendarPicker";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { openDialog } from "../../../redux/reducers/confirmationDialog";
import { useDispatch } from "react-redux";
import { useReschedule } from "../../../query/mutations";
import { enqueueSnackbar } from "notistack";

const DEFAULT_TRANSACTION_DETAIL: GetPublicTransactionByIdResponse = {
  transactionID: "",
  companyID: "",
  timezone: "Asia/Jakarta",
  companyName: "",
  companyLogo: "",
  date: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  productDetail: [],
  location: "",
  adminPrice: 0,
  productPrice: 0,
  total: 0,
  notes: "",
  paymentStatus: PAYMENT_STATUS.PENDING,
  paidWith: "",
  snapToken: "",
  snapRedirectURL: "",
  refundReason: "",
  isManuallyAdded: false,
  transactionTimestamp: {
    paidAt: null,
    requestRefundAt: null,
    refundedAt: null,
    refundCancelledAt: null,
    cancelledAt: null,
  },
  linkID: 0,
  linkURL: "",
  allowRefund: false,
  createdAt: new Date(),
  updatedAt: new Date(),
};

const DRescheduleDetail: React.FC = () => {
  const { data } = useDynamicPage();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { subdomain } = useDynamicPage();
  const dispatch = useDispatch();

  const navigate = useNavigateDynamicPage();

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [scheduleDetailsWithQuantities, setScheduleDetailsWithQuantities] =
    useState<{ [date: string]: Array<PublicScheduleDetailWithQty> }>({});

  let { state } = useLocation();
  const transactionByIdQuery = useGetPublicTransactionById(
    subdomain,
    invoiceId || ""
  );
  const productScheduleByIdQuery = useGetPublicProductScheduleByIdV2(
    state.productID || "",
    (selectedDate || dayjs()).format("YYYY-MM-DD") ||
      dayjs()?.format("YYYY-MM-DD")
  );
  const checkoutDetailByProductIdQuery = useGetCheckoutDetailById(
    state.productID || ""
  );
  const checkoutData = useMemo(() => {
    return checkoutDetailByProductIdQuery.data?.data?.data;
  }, [checkoutDetailByProductIdQuery.data?.data?.data]);

  const productScheduleData = useMemo(() => {
    return productScheduleByIdQuery.data?.data.data || { scheduleDetails: [] };
  }, [productScheduleByIdQuery.data]);

  const transactionData: GetPublicTransactionByIdResponse = useMemo(() => {
    return transactionByIdQuery.data?.data.data || DEFAULT_TRANSACTION_DETAIL;
  }, [transactionByIdQuery.data]);

  const isLoading = useMemo(() => {
    return transactionByIdQuery.isLoading;
  }, [transactionByIdQuery.isLoading]);

  const initialSchedule = useMemo(() => {
    return state?.schedule as ScheduleDetailWithQty;
  }, [state.schedule]);

  const rescheduleMutation = useReschedule();

  const titleSx: SxProps<Theme> = {
    fontWeight: 600,
    fontSize: 16,
  };

  useEffect(() => {
    if (
      productScheduleByIdQuery.isFetched &&
      !productScheduleByIdQuery.isLoading
    ) {
      const selectedDateKey = selectedDate.format("DD-MM-YYYY");
      const fetchedScheduleDetails = productScheduleData.scheduleDetails;

      if (!scheduleDetailsWithQuantities[selectedDateKey]) {
        const initializedScheduleDetails = fetchedScheduleDetails.map(
          (detail) => ({
            ...detail,
            qty: 0,
          })
        );

        setScheduleDetailsWithQuantities((prevState) => ({
          ...prevState,
          [selectedDateKey]: initializedScheduleDetails,
        }));
      }
    }
  }, [
    productScheduleByIdQuery.isFetched,
    productScheduleByIdQuery.isLoading,
    productScheduleData.scheduleDetails,
    scheduleDetailsWithQuantities,
    selectedDate,
  ]);

  const handleReschedule = async (schedule: ScheduleDetailWithQty) => {
    try {
      const rescheduleRequest: RescheduleProductRequest = {
        transactionID: invoiceId || "",
        prevScheduleDetail: {
          date: state.schedule.date,
          startTime: state.schedule.startTime,
          endTime: state.schedule.endTime,
          quota: state.schedule.qty,
        },
        newScheduleDetail: {
          date: schedule.date,
          startTime: schedule.startTime,
          endTime: schedule.endTime,
          quota: state.schedule.qty,
        },
      };
      await rescheduleMutation.mutateAsync(rescheduleRequest);
      enqueueSnackbar(`Penjadwalan Ulang Berhasil`, {
        variant: "success",
        ...DEFAULT_SNACKBAR_PROPS,
      });
      navigate(`${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${invoiceId}`);
    } catch (e) {}
  };

  const handleScheduleSelect = (schedule: ScheduleDetailWithQty) => {
    dispatch(
      openDialog({
        title: "Apakah anda yakin?",
        message: "Apakah anda yakin untuk memilih jadwal baru ini?",
        primaryBtn: {
          text: "Tidak",
          onClick: () => {},
        },
        secondaryBtn: {
          text: "Yakin",
          onClick: () => handleReschedule(schedule),
        },
      })
    );
  };

  const handleBackToHome = () => {
    navigate("/");
  };

  const _renderLoadingSkeleton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          color: "black",
          justifyContent: "center",
        }}
      >
        <Skeleton variant="rounded" width="45%" />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gap={2}
        >
          <Skeleton variant="rounded" width="35%" height={15} />
          <Skeleton variant="rounded" width="35%" height={15} />
        </Box>
      </Box>
    );
  };

  const _renderSchedules = () => {
    if (productScheduleData.scheduleDetails.length === 0) {
      return <Typography>Tidak Ada jadwal hari ini</Typography>;
    }
    return productScheduleData.scheduleDetails.map((detail, index) => {
      const formattedDate = selectedDate.format("DD-MM-YYYY");
      const scheduleDetail: PublicScheduleDetailWithQty =
        scheduleDetailsWithQuantities[formattedDate]?.find(
          (d) => d.startTime === detail.startTime
        ) || { ...detail, qty: 0 };

      const startTime = dayjs(detail.startTime, "HH.mm")
        .set("date", selectedDate.date())
        .set("month", selectedDate.month())
        .set("year", selectedDate.year());
      const endTime = dayjs(detail.endTime, "HH.mm")
        .set("date", selectedDate.date())
        .set("month", selectedDate.month())
        .set("year", selectedDate.year());

      const currentTime = dayjs();
      const timeDifference = endTime.diff(currentTime, "minute");
      const timePassed = timeDifference < 0;

      const disabled =
        detail.price !== (initialSchedule?.price || 0) ||
        detail.remainingQuota < initialSchedule?.qty ||
        timePassed;
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            my: 2,
            pointerEvents: disabled ? "none" : "auto",
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <OpacityButton
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: 1,
              borderRadius: 4,
              backgroundColor: "white",
              width: "100%",
              "&:hover": {
                opacity: 0.5,
                cursor: "pointer",
              },
              padding: 1,
            }}
            disabled={
              detail.price !== (initialSchedule?.price || 0) ||
              detail.remainingQuota < initialSchedule?.qty ||
              timePassed
            }
            onClick={() =>
              handleScheduleSelect({
                ...scheduleDetail,
                date: selectedDate.format("YYYY-MM-DD"),
              })
            }
          >
            <Typography fontSize={16} fontWeight={600}>
              {`${startTime.format("HH.mm")} - ${endTime.format("HH.mm")}`}
              <Typography
                fontSize={12}
                fontWeight={400}
                color={COLOR.danger400}
                variant="caption"
                ml="4px"
              >
                (Sisa {detail.remainingQuota})
              </Typography>
            </Typography>
            <Typography
              mt="8px"
              fontSize={16}
              fontWeight={600}
              color={COLOR.neutral500}
            >
              {formatCurrency(detail.price, "IDR")}
            </Typography>
            {/* </Box> */}
          </OpacityButton>
          {/* <QuantitySelector
            value={scheduleDetail.qty}
            onChange={(qty) => handleQuantityChange(index, qty)}
            min={0}
            max={detail.remainingQuota}
            maxDigits={2}
            // primaryColor={FONT_COLOR}
            disabled={disabled}
          /> */}
        </Box>
      );
    });
  };

  const generateScheduleLabel = (schedule: ScheduleDetailWithQty) => {
    const start = dayjs(schedule.startTime, "HH.mm");
    const end = dayjs(schedule.endTime, "HH.mm");
    const date = dayjs(schedule.date, "YYYY-MM-DD");
    return `${date.format("DD MMM YYYY")} / ${start.format(
      "HH.mm"
    )} - ${end.format("HH.mm")} ${
      TIMEZONE_SHORTLABEL?.[transactionData.timezone || "Asia/Jakarta"] || ""
    } (${end.diff(start, "minute")} menit) - ${schedule.qty} item`;
  };

  return (
    <AppContainer
      sx={{
        backgroundColor: data.color,
        padding: 2,
        height: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 4,
          py: 2,
        }}
      >
        {isLoading ? (
          _renderLoadingSkeleton()
        ) : (
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 4,
              py: 2,
            }}
          >
            <Typography px={2} sx={titleSx}>
              Pilih Produk yang Ingin Dijadwalkan Ulang
            </Typography>
            {/* Render selected schedule and new schedule time fields */}
            <Box sx={{ px: 2, py: 1 }}>
              <Typography variant="h6">Jadwal Awal:</Typography>
              {state?.schedule ? (
                <Typography>{generateScheduleLabel(state.schedule)}</Typography>
              ) : (
                <Typography>Tidak ada jadwal yang dipilih</Typography>
              )}
            </Box>

            <Box sx={{ px: 2, py: 1 }}>
              <Typography variant="h6">Pilih Jadwal Pengganti:</Typography>
              <Box px={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  width="100%"
                  alignItems="center"
                  py={2}
                >
                  <Box width="100%" textAlign="center" pb={2}>
                    <Typography variant="caption">Hari yang dipilih</Typography>
                    <Typography fontWeight={500} variant="h5">
                      {selectedDate.format("DD MMM YYYY")}
                    </Typography>
                  </Box>
                  <CalendarPicker
                    baseColor={FONT_COLOR}
                    primaryColor={data.color}
                    minDate={dayjs()}
                    maxDate={dayjs().add(
                      checkoutData?.reservationPeriod === undefined
                        ? 60
                        : checkoutData?.reservationPeriod,
                      "days"
                    )}
                    value={selectedDate}
                    onChange={(date) => {
                      if (date) {
                        setSelectedDate(date);
                      }
                    }}
                  />
                </Box>
              </Box>

              {_renderSchedules()}
            </Box>
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="column" gap={2} pt={2}>
        <TemplateButton
          onClick={handleBackToHome}
          backgroundColor="white"
          color={COLOR.neutral900}
          buttonText="Kembali Ke Halaman Utama"
        />
      </Box>
    </AppContainer>
  );
};

export default DRescheduleDetail;
