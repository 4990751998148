import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { RoutePath } from "../utils/constant";
import isEmpty from "lodash.isempty";
import { useGetDataByDomainName } from "../query/queries";
import { createContext, useContext, useMemo } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  PageTemplateType,
  PublicCompanyWebsiteResponse,
} from "../api/request.types";

interface DynamicPageContextType {
  data: PublicCompanyWebsiteResponse;
  subdomain: string;
  loading: boolean;
}

const DynamicPageContext = createContext<DynamicPageContextType | undefined>(
  undefined
);

const NavigateOnValidCompanyNameRoute = () => {
  const params = useParams<{ companyDomainName: string }>();
  const location = useLocation();
  const invalidCompanyName = isEmpty(params.companyDomainName);
  const isAdminPage = params.companyDomainName === "admin";
  const getDataByDomainNameQuery = useGetDataByDomainName(
    params.companyDomainName || "",
    { retry: false }
  );
  const domainNameData = useMemo(() => {
    return (
      getDataByDomainNameQuery.data?.data?.data || {
        companyWebsiteID: "",
        companyID: "",
        companyDomainName: "",
        logoPath: "",
        logoSignedURL: "",
        template: "Type1" as PageTemplateType,
        color: "#D5D5D5",
        name: "",
        description: "",
        products: [],
        openingHours: "",
      }
    );
  }, [getDataByDomainNameQuery]);

  const { companyDomainName: subdomain = "" } = useParams<{
    companyDomainName: string;
  }>();

  // const NOT_FOUND = (
  //   <Navigate
  //     to={RoutePath.DYNAMIC_NOT_FOUND}
  //     state={{ from: location }}
  //     replace
  //   />
  // );

  // if (invalidCompanyName) {
  //   return NOT_FOUND;
  // }

  // if (isAdminPage) {
  //   return <Navigate to={RoutePath.LOGIN} state={{ from: location }} replace />;
  // }

  // if (
  //   getDataByDomainNameQuery.isLoading &&
  //   !getDataByDomainNameQuery.isFetched
  // ) {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       width="100%"
  //       height="100%"
  //       minHeight="100vh"
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  // if (getDataByDomainNameQuery.isError) {
  //   return NOT_FOUND;
  // }

  // if (getDataByDomainNameQuery.isSuccess) {
    return (
      <DynamicPageContext.Provider
        value={{
          subdomain,
          data: domainNameData,
          loading: getDataByDomainNameQuery.isLoading,
        }}
      >
        <Outlet />
      </DynamicPageContext.Provider>
    );
  // }
  // return NOT_FOUND;
};

export const useDynamicPage = (): DynamicPageContextType => {
  const context = useContext(DynamicPageContext);
  if (context === undefined) {
    throw new Error("useDynamicPage must be used within a DataProvider");
  }
  return context;
};

export default NavigateOnValidCompanyNameRoute;
