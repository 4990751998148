import { FiHome } from "react-icons/fi";
import { IoStorefrontOutline } from "react-icons/io5";
import { MdOutlineAccountCircle, MdOutlineMail } from "react-icons/md";
import { FiCalendar } from "react-icons/fi";
import { BottomNavItem } from "../components/BottomNav";
import { OptionsObject } from "notistack";
import { GetProductByIdResponse, PageTemplateType } from "../api/request.types";
import {
  FaDiscord,
  FaEtsy,
  FaFacebook,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaPinterest,
  FaSnapchat,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";

export enum ROUTE_NAME {
  HOME = "HOME",
  SCHEDULE = "SCHEDULE",
  BUSINESS = "BUSINESS",
  CREATE_BUSINESS = "CREATE_BUSINESS",
  PROFILE = "PROFILE",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BANK_ACCOUNT_OTP = "BANK_ACCOUNT_OTP",
  WITHDRAW = "WITHDRAW",
  WITHDRAW_OTP = "WITHDRAW_OTP",
  UNAUTHORIZED = "UNAUTHORIZED",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOCATION_BUSINESS = "LOCATION_BUSINESS",
  PAGE_EDITOR = "PAGE_EDITOR",
  PAGE_PREVIEW = "PAGE_PREVIEW",
  PAGE_TEMPLATE = "PAGE_TEMPLATE",
  ADD_PRODUCT = "ADD_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  STATUS_INFO = "STATUS_INFO",
  MANAGE_SCHEDULE = "MANAGE_SCHEDULE",
  DYNAMIC_PRODUCT_DETAIL = "DYNAMIC_PRODUCT_DETAIL",
  DYNAMIC_SCHEDULE = "DYNAMIC_SCHEDULE",
  DYNAMIC_HOME = "DYNAMIC_HOME",
  DYNAMIC_NOT_FOUND = "DYNAMIC_NOT_FOUND",
  DYNAMIC_INVOICE = "DYNAMIC_INVOICE",
  DYNAMIC_RESCHEDULE = "DYNAMIC_RESCHEDULE",
  DYNAMIC_RESCHEDULE_DETAIL = "DYNAMIC_RESCHEDULE_DETAIL",
  DYNAMIC_PAYMENT_DETAIL = "DYNAMIC_PAYMENT_DETAIL",
  DYNAMIC_STATUS_INFO = "DYNAMIC_STATUS_INFO",
  DYNAMIC_CHECKOUT = "DYNAMIC_CHECKOUT",
  TRANSACTION = "TRANSACTION",
  TOP_PRODUCT = "TOP_PRODUCT",
  PRODUCT = "PRODUCT",
  EDIT_BUSINESS = "EDIT_BUSINESS",
  CHOOSE_COMPANY = "CHOOSE_COMPANY",
  RESET_PASSWORD = "RESET_PASSWORD",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  REQUEST_FORGOT_PASSWORD = "REQUEST_FORGOT_PASSWORD",
  EVENTS = "EVENTS",
}

export const FONT_COLOR = "black";
export type RoutePathType = { [key in ROUTE_NAME]: string };

export const RoutePath: RoutePathType = {
  [ROUTE_NAME.HOME]: "/admin/home",
  [ROUTE_NAME.CHOOSE_COMPANY]: "/admin/company",
  [ROUTE_NAME.RESET_PASSWORD]: "/admin/reset-password",
  [ROUTE_NAME.REQUEST_FORGOT_PASSWORD]: "/admin/request-forgot-password",
  [ROUTE_NAME.SCHEDULE]: "/admin/schedule",
  [ROUTE_NAME.BUSINESS]: "/admin/business",
  [ROUTE_NAME.CREATE_BUSINESS]: "/admin/business/create",
  [ROUTE_NAME.LOCATION_BUSINESS]: "/admin/business/location",
  [ROUTE_NAME.PAGE_PREVIEW]: "/admin/business/preview",
  [ROUTE_NAME.PAGE_TEMPLATE]: "/admin/business/template",
  [ROUTE_NAME.ADD_PRODUCT]: "/admin/product/add-product",
  [ROUTE_NAME.EDIT_PRODUCT]: "/admin/product",
  [ROUTE_NAME.PROFILE]: "/admin/profile",
  [ROUTE_NAME.BANK_ACCOUNT]: "/admin/bankAccount",
  [ROUTE_NAME.BANK_ACCOUNT_OTP]: "/admin/bankAccount/otp",
  [ROUTE_NAME.WITHDRAW]: "/admin/withdraw",
  [ROUTE_NAME.WITHDRAW_OTP]: "/admin/withdraw/otp",
  [ROUTE_NAME.UNAUTHORIZED]: "/admin/unauthorized",
  [ROUTE_NAME.LOGIN]: "/admin/login",
  [ROUTE_NAME.REGISTER]: "/admin/register",
  [ROUTE_NAME.PAGE_EDITOR]: "/admin/page-editor",
  [ROUTE_NAME.STATUS_INFO]: "/admin/status-info",
  [ROUTE_NAME.TRANSACTION]: "/admin/transaction",
  [ROUTE_NAME.TOP_PRODUCT]: "/admin/top-product",
  [ROUTE_NAME.PRODUCT]: "/admin/product",
  [ROUTE_NAME.EDIT_BUSINESS]: "/admin/business",
  [ROUTE_NAME.EVENTS]: "/admin/events",
  [ROUTE_NAME.VERIFY_EMAIL]: "/verify-email",
  [ROUTE_NAME.FORGOT_PASSWORD]: "/forgot-password",
  [ROUTE_NAME.DYNAMIC_HOME]: "/:companyDomainName",
  [ROUTE_NAME.DYNAMIC_RESCHEDULE_DETAIL]: "/:companyDomainName/inv",
  [ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL]: "/:companyDomainName/detail",
  [ROUTE_NAME.DYNAMIC_SCHEDULE]: "/:companyDomainName/schedule",
  [ROUTE_NAME.MANAGE_SCHEDULE]: "/:companyDomainName/manage-schedule",
  [ROUTE_NAME.DYNAMIC_INVOICE]: "/:companyDomainName/inv",
  [ROUTE_NAME.DYNAMIC_RESCHEDULE]: "/:companyDomainName/inv",
  [ROUTE_NAME.DYNAMIC_NOT_FOUND]: "/not-found",
  [ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL]: "/:companyDomainName/payment",
  [ROUTE_NAME.DYNAMIC_STATUS_INFO]: "/:companyDomainName/info",
  [ROUTE_NAME.DYNAMIC_CHECKOUT]: "/:companyDomainName/checkout",
};

export const COOKIE = {
  TOKEN: "TOKEN",
  USER_UUID: "USER_UUID",
};

export const USER_ROLE = {
  GUEST: "guest",
  ADMIN: "admin",
  STAFF: "staff",
  USER: "user",
};

export const DEFAULT_BOTTOM_NAVIGATION: Array<BottomNavItem> = [
  {
    routeName: ROUTE_NAME.HOME,
    routePath: RoutePath[ROUTE_NAME.HOME],
    label: "Beranda",
    icon: <FiHome />,
  },
  {
    routeName: ROUTE_NAME.SCHEDULE,
    routePath: RoutePath[ROUTE_NAME.SCHEDULE],
    label: "Jadwal",
    icon: <FiCalendar />,
  },
  {
    routeName: ROUTE_NAME.BUSINESS,
    routePath: RoutePath[ROUTE_NAME.BUSINESS],
    label: "Bisnis",
    icon: <IoStorefrontOutline />,
  },
  {
    routeName: ROUTE_NAME.PROFILE,
    routePath: RoutePath[ROUTE_NAME.PROFILE],
    label: "Profil",
    icon: <MdOutlineAccountCircle />,
  },
];

// Remember change the value on index.css too (since it impact bottomsheet)
export const MAX_PAGE_WIDTH = 800;
export const MIN_FONT_SIZE_FORM = 16;
export const MIN_FONT_SIZE_BUTTON_ICON = 24;

export const SX_P_M_SIZE = 3;

export enum STORAGE_KEY {
  COLOR_THEME = "color-theme",
  CREATE_BUSINESS_IMAGE_PREVIEW = "create-business-image-preview",
  BUSINESS_DATA_ERROR = "business-data-error",
  TEMPLATE_DATA = "template-data",
  LOCATION_DATA = "location-data",
  PRODUCT_IMAGES = "product-images",
  ORDER_ID = "order-id",
}

export const DEFAULT_GEOLOCATION = {
  lat: 0,
  long: 0,
};

export const TIMEZONES = ["Asia/Jakarta", "Asia/Makassar"];

export const TIMEZONE_SHORTLABEL = {
  [TIMEZONES[0]]: "WIB",
  [TIMEZONES[1]]: "WITA",
};

export const TIMEZONE_LABEL = {
  [TIMEZONES[0]]: "Waktu Indonesia Barat (WIB), UTC+07:00",
  [TIMEZONES[1]]: "Waktu Indonesia Tengah (WITA), UTC+08:00",
};

// Secondary Color based on
// https://www.canva.com/colors/color-wheel/
export const TEMPLATE_HEX_COLOR = [
  {
    primary: "#3B82F6",
    secondary: "#93C5FD",
  },
  {
    primary: "#EF4444",
    secondary: "#EB1515",
  },
  {
    primary: "#F59E0B",
    secondary: "#C57F08",
  },
  {
    primary: "#22C55E",
    secondary: "#3DDD78",
  },
  {
    primary: "#8B5CF6",
    secondary: "#692CF3",
  },
];

export const TEMPLATE_PAGE: Array<{ id: PageTemplateType; content: string }> = [
  {
    id: "Type1",
    content: "template1",
  },
];

export enum DURATION_PERIOD {
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
  WEEKS = "weeks",
}

export const DEFAULT_SNACKBAR_PROPS: OptionsObject<"default"> = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  autoHideDuration: 2000,
};

export const UNIT_TEXT = {
  [DURATION_PERIOD.DAYS]: "Hari",
  [DURATION_PERIOD.HOURS]: "Jam",
  [DURATION_PERIOD.MINUTES]: "Menit",
  [DURATION_PERIOD.WEEKS]: "Minggu",
};

export const NEEMA_URL = window.location.origin;
export const getBusinessPreviewUrl = (companyUrl: string): string => {
  return `${NEEMA_URL}/${companyUrl}`;
};

export const DEFAULT_PRODUCT_DATA: GetProductByIdResponse = {
  productID: "",
  companyID: "",
  name: "",
  description: "",
  useSinglePrice: false,
  allowRefund: false,
  disabled: false,
  duration: 0,
  durationPeriod: DURATION_PERIOD.MINUTES,
  quota: 0,
  photos: [],
  photosSignedURL: [],
  location: {
    mapLocation: "",
    completeAddress: "",
    useManualLocation: false,
    longitude: 0,
    latitude: 0,
    timezone: "",
  },
  scheduleDetails: {},
  addOn: [],
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
};

export enum PRODUCT_TYPE {
  RESERVATION = "reservation",
  LINK = "link",
  SECTION = "section",
}

export type MAIN_PRODUCT_TYPE = PRODUCT_TYPE.RESERVATION | PRODUCT_TYPE.LINK;

export enum TAB_ENUM {
  THUMB = "thumb",
  CHECKOUT = "checkout",
  AVAILABILITY = "availability",
}

export enum THUMB_STYLE {
  BUTTON = "button",
  CALLOUT = "callout",
  PREVIEW = "preview",
}

export const SOCIAL_MEDIA = {
  tiktok: FaTiktok,
  instagram: FaInstagram,
  youtube: FaYoutube,
  email: MdOutlineMail,
  discord: FaDiscord,
  twitter: FaTwitter,
  twitch: FaTwitch,
  facebook: FaFacebook,
  snapchat: FaSnapchat,
  linkedin: FaLinkedin,
  pinterest: FaPinterest,
  vimeo: FaVimeo,
  etsy: FaEtsy,
  link: FaLink,
};

export const NEEMA_LOGO_SIGNED_URL =
  "https://storage.googleapis.com/neema-b7c3b.appspot.com/public-web-assets/logo-white.png";

export const MAX_SIZE_IMAGE = 2 * 1024 * 1024; // 2MB
