import { Box, Typography } from '@mui/material';
import { PiCaretUpBold } from 'react-icons/pi';
import { COLOR } from '../../../../utils/color';
import StatusText from '../../../../components/StatusText/StatusText';

interface ScheduleAccordionHeaderProps {
  open: boolean;
  startTime: string;
  endTime: string;
  remainingQuota: number;
  totalQuota: number;
  isOverdue?: boolean;
}

export const ScheduleAccordionHeader: React.FC<ScheduleAccordionHeaderProps> = ({
  open, startTime, endTime, remainingQuota, totalQuota, isOverdue = false,
}) => {
  const scheduleCount = Math.max(0, totalQuota - remainingQuota);
  return (
    <Box sx={{ width: '100%' }} display="flex" flexDirection="column">
      <Typography variant='caption' fontSize={16} fontWeight={500} p={1}>
        {isOverdue && <StatusText backgroundColor={COLOR.neutral200} color={COLOR.neutral600} sx={{
          px: "12px",
          py: "2px",
        }}>
          Sudah Berlalu
        </StatusText>}
        {startTime}-{endTime} WIB
        <Typography ml="4px" variant='caption' color={COLOR.danger500}>
          (Sisa {remainingQuota} dari {totalQuota})
        </Typography>
      </Typography>
      <Box width="100%" height="1px" sx={{ backgroundColor: COLOR.neutral300 }} />
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" p={1} color={COLOR.neutral500}>
        <Typography variant='caption' fontSize={12} fontWeight={500}>
          Detail Jadwal {scheduleCount > 0 && `(${scheduleCount})`}
        </Typography>
        <PiCaretUpBold style={{ transform: open ? 'initial' : 'rotate(180deg)', transition: 'transform 0.3s ease' }} />
      </Box>
    </Box>
  )
};
