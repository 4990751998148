import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_NAME, RoutePath, USER_ROLE } from "../utils/constant";

import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import Schedule from "../pages/Schedule/Schedule";
import Business from "../pages/Business/Business";
import NotFound from "../pages/NotFound";
import RequireAuthRoute from "./RequireAuth";
import Login from "./UnAuthorizedPage/LoginPage/Login";
// import Register from "./UnAuthorizedPage/RegisterPage/Register";
import NavigateOnAuth from "./NavigateOnAuth";
import CreateBusiness from "./Business/CreateBusiness/CreateBusiness";
import { BottomNavProvider } from "../context/BottomNavContext";
import LocationBusiness from "./Business/LocationBusiness/LocationBusiness";
// import PageEditor from "../components/PageEditor/PageEditor";
import PageTemplate from "./Business/PageTemplate/PageTemplate";
import Product from "./Business/Product/Product";
import StatusInfo from "./StatusInfo/StatusInfo";
import ManageSchedule from "./Business/ManageSchedule/ManageSchedule";
import { LocationProvider } from "../context/LocationPageContext";
import { StatusInfoProvider } from "../context/StatusInfoContext";
import { ManageScheduleProvider } from "../context/ScheduleContext";
import Transaction from "./Transaction/Transaction";
import ProductList from "./ProductList/ProductList";
import DetailProduct from "./ProductDetail/ProductDetail";
import TransactionDetail from "./TransactionDetail/TransactionDetail";
import EditBusiness from "./Business/EditBusiness/EditBusiness";
import NavigateOnValidCompanyNameRoute from "./NavigateOnValidCompanyName";
import D2Home from "./DynamicPage/Template2/D2Home";
import D2Checkout from "./DynamicPage/Template2/D2Checkout";
import DProduct from "./DynamicPage/Template1/DProduct";
import DInvoice from "./DynamicPage/Template1/DInvoice";
import DSchedule from "./DynamicPage/Template1/DSchedule";
import DPaymentDetail from "./DynamicPage/Template1/DPaymentDetail";
import ChooseCompany from "./ChooseCompany/ChooseCompany";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyEmail from "./VerifyEmail";
import ForgotPassword from "./ForgotPassword";
import RequestForgotPassword from "./RequestForgotPassword";
import EventPage from "./EventPage/EventPage";
import TopProduct from "./TopProduct/TopProduct";
import BankAccount from "./BankAccount/BankAccount";
import BankAccountOTP from "./BankAccount/OTP/OTP";
import Withdraw from "./Withdraw/Withdraw";
import WithdrawOTP from "./Withdraw/OTP/OTP";
import DReschedule from "./DynamicPage/Template1/DReschedule";
import DRescheduleDetail from "./DynamicPage/Template1/DRescheduleDetail";

const RouteList = () => {
  return (
    <BrowserRouter>
      <BottomNavProvider>
        <StatusInfoProvider>
          <ManageScheduleProvider>
            <LocationProvider>
              <Routes>
                <>
                  <Route element={<NavigateOnValidCompanyNameRoute />}>
                    <Route
                      path={RoutePath[ROUTE_NAME.DYNAMIC_HOME]}
                      element={<D2Home />}
                    />
                    <Route
                      path={
                        RoutePath[ROUTE_NAME.DYNAMIC_CHECKOUT] + "/:productId"
                      }
                      element={<D2Checkout />}
                    />
                    {/* Default component for subdomain */}
                    <Route
                      path={
                        RoutePath[ROUTE_NAME.DYNAMIC_PRODUCT_DETAIL] +
                        "/:productId"
                      }
                      element={<DProduct />}
                    />
                    <Route
                      path={
                        RoutePath[ROUTE_NAME.DYNAMIC_INVOICE] + "/:invoiceId"
                      }
                      element={<DInvoice />}
                    />
                    <Route
                      path={
                        RoutePath[ROUTE_NAME.DYNAMIC_RESCHEDULE] +
                        "/:invoiceId/reschedule"
                      }
                      element={<DReschedule />}
                    />
                    <Route
                      path={
                        RoutePath[ROUTE_NAME.DYNAMIC_RESCHEDULE_DETAIL] +
                        "/:invoiceId/reschedule/detail"
                      }
                      element={<DRescheduleDetail />}
                    />
                    <Route
                      path={`${
                        RoutePath[ROUTE_NAME.DYNAMIC_SCHEDULE]
                      }/:productId`}
                      element={<DSchedule />}
                    />
                    <Route
                      path={RoutePath[ROUTE_NAME.DYNAMIC_PAYMENT_DETAIL]}
                      element={<DPaymentDetail />}
                    />
                    <Route
                      path={RoutePath[ROUTE_NAME.DYNAMIC_STATUS_INFO]}
                      element={<StatusInfo />}
                    />
                    <Route
                      path="*"
                      element={
                        <Navigate to={RoutePath[ROUTE_NAME.DYNAMIC_HOME]} />
                      }
                    />
                  </Route>
                  <Route
                    path={RoutePath[ROUTE_NAME.DYNAMIC_NOT_FOUND]}
                    element={<NotFound />}
                  />
                  <Route element={<NavigateOnAuth to={RoutePath.HOME} />}>
                    <Route path={RoutePath.LOGIN} element={<Login />} />
                    {/* <Route path={RoutePath.REGISTER} element={<Register />} /> */}
                  </Route>
                  <Route
                    element={
                      <RequireAuthRoute
                        allowedRoles={[
                          USER_ROLE.ADMIN,
                          USER_ROLE.STAFF,
                          USER_ROLE.GUEST,
                        ]}
                      />
                    }
                  >
                    <Route path={RoutePath.HOME} element={<Home />} />
                    <Route
                      path={RoutePath.CHOOSE_COMPANY}
                      element={<ChooseCompany />}
                    />
                    <Route
                      path={RoutePath.RESET_PASSWORD}
                      element={<ResetPassword />}
                    />
                    <Route path={RoutePath.SCHEDULE} element={<Schedule />} />
                    <Route path={RoutePath.BUSINESS} element={<Business />} />
                    <Route path={RoutePath.EVENTS} element={<EventPage />} />
                    <Route
                      path={RoutePath.CREATE_BUSINESS}
                      element={<CreateBusiness />}
                    />
                    <Route
                      path={RoutePath.TRANSACTION}
                      element={<Transaction />}
                    />
                    <Route
                      path={RoutePath.TOP_PRODUCT}
                      element={<TopProduct />}
                    />
                    <Route
                      path={RoutePath.EDIT_BUSINESS + "/:businessId/edit"}
                      element={<EditBusiness />}
                    />
                    {/* <Route path={RoutePath.PAGE_EDITOR} element={<PageEditor />} /> */}
                    <Route
                      path={RoutePath.PAGE_TEMPLATE}
                      element={<PageTemplate />}
                    />
                    <Route
                      path={RoutePath.ADD_PRODUCT + "/:productId"}
                      element={<Product />}
                    />
                    <Route
                      path={RoutePath.EDIT_PRODUCT + "/:productId/edit"}
                      element={<Product />}
                    />
                    <Route path={RoutePath.PROFILE} element={<Profile />} />
                    <Route
                      path={RoutePath.BANK_ACCOUNT}
                      element={<BankAccount />}
                    />
                    <Route
                      path={RoutePath.BANK_ACCOUNT_OTP}
                      element={<BankAccountOTP />}
                    />
                    <Route path={RoutePath.WITHDRAW} element={<Withdraw />} />
                    <Route
                      path={RoutePath.WITHDRAW_OTP}
                      element={<WithdrawOTP />}
                    />
                    <Route
                      path={RoutePath.LOCATION_BUSINESS}
                      element={<LocationBusiness />}
                    />
                    <Route
                      path={RoutePath.STATUS_INFO}
                      element={<StatusInfo />}
                    />
                    <Route
                      path={RoutePath.MANAGE_SCHEDULE}
                      element={<ManageSchedule />}
                    />

                    <Route
                      path={RoutePath.TRANSACTION + "/:transactionId"}
                      element={<TransactionDetail />}
                    />
                    <Route path={RoutePath.PRODUCT} element={<ProductList />} />
                    <Route
                      path={RoutePath.PRODUCT + "/:productId/:mode"}
                      element={<DetailProduct />}
                    />
                  </Route>
                  <Route
                    path={RoutePath[ROUTE_NAME.REQUEST_FORGOT_PASSWORD]}
                    element={<RequestForgotPassword />}
                  />
                  <Route
                    path={RoutePath[ROUTE_NAME.VERIFY_EMAIL]}
                    element={<VerifyEmail />}
                  />
                  <Route
                    path={RoutePath[ROUTE_NAME.FORGOT_PASSWORD]}
                    element={<ForgotPassword />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/" element={<Navigate to={RoutePath.LOGIN} />} />
                </>
              </Routes>
            </LocationProvider>
          </ManageScheduleProvider>
        </StatusInfoProvider>
      </BottomNavProvider>
    </BrowserRouter>
  );
};

export default RouteList;
