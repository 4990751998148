import { Box, Skeleton, Typography, debounce } from "@mui/material";
import AppContainer from "../../../components/Layout/AppContainer/AppContainer";
import {
  DEFAULT_SNACKBAR_PROPS,
  ROUTE_NAME,
  RoutePath,
} from "../../../utils/constant";
import { useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash.isempty";
import MarkdownRenderer from "../../../components/MarkdownRenderer/MarkdownRenderer";
import dayjs, { Dayjs } from "dayjs";
import QuantitySelector from "../../../components/QuantityInput/QuantityInput";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { COLOR } from "../../../utils/color";
import PhoneIcon from "../../../assets/svg/PhoneIcon";
import TextInput from "../../../components/Forms/TextInput/TextInput";
import MailIcon from "../../../assets/svg/MailIcon";
import UserIcon from "../../../assets/svg/UserIcon";
import {
  useGetCheckoutDetailById,
  useGetPublicCompanyDomainV2,
  useGetPublicProductById,
  useGetPublicProductScheduleByIdV2,
} from "../../../query/queries";
import { PublicScheduleDetailWithQty } from "../../../types/globalTypes";
import {
  formatCurrency,
  formatPhoneNumber,
  stripFormatting,
} from "../../../utils/helper";
import {
  useCreateNewTransaction,
  useGetPublicCompanyDetail,
} from "../../../query/mutations";
import { isAxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../../utils/logger";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import {
  receipentSchema,
  receipentWARequiredSchema,
} from "../../../joiSchema/payment";
import Joi, { ValidationErrorItem } from "joi";
import {
  GetPublicProductByIdResponse,
  ProductDetailType,
  ScheduleProductDetail,
} from "../../../api/request.types";
import CalendarPicker from "../../../components/CalendarPIcker/CalendarPicker";
import OpacityButton from "../../../components/OpacityButton/OpacityButton";
import { FaCaretLeft } from "react-icons/fa";
import { IoMdShare } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "../../../redux/reducers";
import {
  resetPublicTransaction,
  setPublicTransaction,
} from "../../../redux/reducers/public";
import useNavigateDynamicPage from "../../../hooks/useNavigateDynamicPage";
import DynamicPageContainer from "../../../components/Layout/DynamicPageContainer/DynamicPageContainer";
import { getTransactionTax, QRIS_TAX } from "../../../utils/transaction";
import useUpdateParams from "../../../hooks/useUpdateParams";

type PaymentReceipentType = {
  name: string;
  email: string;
  phone: string;
  notes: string;
};

const DEFAULT_PAYMENT_RECEIPENT_DATA = {
  name: "",
  email: "",
  phone: "",
  notes: "",
};

const D2Checkout = () => {
  const FONT_COLOR = "black";
  const dispatch = useDispatch();
  const navigate = useNavigateDynamicPage();
  const { subdomain } = useDynamicPage();

  const publicReducer = useSelector(
    (state: RootReducerState) => state.publicReducer
  );
  const { data } = useDynamicPage();
  const { productId } = useParams<{ productId: string }>();
  let { state } = useLocation();
  const getCompanyV2Mutation = useGetPublicCompanyDetail();
  const [productDetailData, setProductDetailData] = useState<{
    data: ProductDetailType;
    selectedDate?: string;
  }>(state);
  const [productDetailDataLoading, setProductDetailDataLoading] =
    useState<boolean>(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedProductData = useCallback(async () => {
    if (!state && !productDetailData) {
      const companyData = await getCompanyV2Mutation.mutateAsync({
        companyDomainName: subdomain || "",
      });
      companyData.data?.data?.productDetails.forEach(
        (product: ProductDetailType) => {
          if (product.productID === productId) {
            setProductDetailData({
              data: product,
              selectedDate: dayjs().format("YYYY-MM-DD"),
            });
            setProductDetailDataLoading(false);
          }
        }
      );
      return null;
    }
    setProductDetailData(
      state as {
        data: ProductDetailType;
        selectedDate?: string;
      }
    );
    setProductDetailDataLoading(false);
  }, [getCompanyV2Mutation, productId, state, subdomain]);

  useEffect(() => {
    if (productDetailDataLoading) {
      selectedProductData();
    }
  }, [productDetailDataLoading]);

  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    state?.selectedDate ? dayjs(state?.selectedDate) : dayjs()
  );
  const [scheduleDetailsWithQuantities, setScheduleDetailsWithQuantities] =
    useState<{ [date: string]: Array<PublicScheduleDetailWithQty> }>({});
  const [paymentReceipent, setPaymentRecipient] =
    useState<PaymentReceipentType>(DEFAULT_PAYMENT_RECEIPENT_DATA);
  const [errors, setErrors] = useState<
    Record<keyof PaymentReceipentType, string>
  >({
    name: "",
    email: "",
    notes: "",
    phone: "",
  });
  const createNewTransactionMutation = useCreateNewTransaction();
  const checkoutDetailByProductIdQuery = useGetCheckoutDetailById(
    productId || ""
  );

  const productScheduleByIdQuery = useGetPublicProductScheduleByIdV2(
    productId || "",
    (selectedDate || dayjs()).format("YYYY-MM-DD") ||
    dayjs()?.format("YYYY-MM-DD")
  );

  const checkoutData = useMemo(() => {
    return checkoutDetailByProductIdQuery.data?.data?.data;
  }, [checkoutDetailByProductIdQuery.data?.data?.data]);

  const isWhatsAppRequired = useMemo(() => {
    return (
      checkoutData?.collectField.find((data) => data.value === "No Whatsapp")
        ?.required ?? false
    );
  }, [checkoutData]);
  const productScheduleData = useMemo(() => {
    return productScheduleByIdQuery.data?.data.data || { scheduleDetails: [] };
  }, [productScheduleByIdQuery.data]);

  const validateError = () => {
    const dynamicSchema = isWhatsAppRequired
      ? receipentWARequiredSchema
      : receipentSchema;
    const { error } = dynamicSchema.validate(paymentReceipent, {
      abortEarly: false,
    });
    if (error) {
      const errors = error.details.reduce(
        (acc: any, err: ValidationErrorItem) => {
          return { ...acc, [err.path[0]]: err.message };
        },
        {}
      );
      setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
      return true;
    }
    return false;
  };
  const handleBlur = (field: keyof PaymentReceipentType) => () => {
    const { error } = Joi.object({
      [field]: receipentSchema.extract(field),
    }).validate({ [field]: paymentReceipent[field] });
    if (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error.message,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };

  const handleOnChange = (key: keyof PaymentReceipentType) => (e: any) => {
    switch (key) {
      case "phone":
        let phoneNumber = e?.target?.value || "";
        phoneNumber = stripFormatting(phoneNumber);
        setPaymentRecipient((prevData) => ({
          ...prevData,
          [key]: phoneNumber,
        }));
        return;
      case "email":
      case "name":
      case "notes":
        setPaymentRecipient((prevData) => ({
          ...prevData,
          [key]: e.target.value,
        }));
        return;
    }
  };

  const handleSubmit = async () => {
    try {
      const hasError = validateError();
      if (!hasError) {
        const productDetail: ScheduleProductDetail[] = [
          {
            productID: productId || "",
            productName: productDetailData?.data?.name,
            scheduleDetails: Object.entries(scheduleDetailsWithQuantities)
              .map(([date, details]) =>
                details
                  .filter((detail) => detail.qty > 0)
                  .map((detail) => ({
                    startTime: dayjs(detail.startTime, "HH.mm").format("HH.mm"),
                    endTime: dayjs(detail.endTime, "HH.mm").format("HH.mm"),
                    date: dayjs(date, "DD-MM-YYYY").format("YYYY-MM-DD"), // Convert to "YYYY-MM-DD"
                    qty: detail.qty,
                    price: detail.price,
                    remainingQuota: detail.remainingQuota,
                  }))
              )
              .flat(),
          },
        ];
        const dataTransaction = {
          companyID: data.companyID,
          date: dayjs().format("YYYY-MM-DD"),
          productDetail,
          location: "",
          total: priceDetail?.total || 0,
          productPrice: priceDetail?.amount || 0,
          adminPrice: priceDetail?.adminFee || 0,
          fullName: paymentReceipent.name,
          email: paymentReceipent.email,
          phoneNumber: paymentReceipent.phone
            ? `+62${paymentReceipent.phone}`
            : "",
          notes: "",
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        const apiResponse = await createNewTransactionMutation.mutateAsync(
          dataTransaction
        );
        const responseData = apiResponse.data.data;
        dispatch(
          setPublicTransaction({
            snapToken: responseData.snapToken,
            transactionId: responseData.transactionID,
            timestamp: dayjs().valueOf(),
          })
        );
        const navigateTo = `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${responseData.transactionID
          }`;
        navigate(navigateTo, { replace: true });
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        const errorData = error.response.data?.data;
        if (Array.isArray(errorData)) {
          errorData.forEach((product) => {
            const { startTime, endTime, date } = product.scheduleDetails;
            const msg = `Kuota pada tanggal ${date} (${startTime}-${endTime}) tidak mencukupi`;

            enqueueSnackbar({
              ...DEFAULT_SNACKBAR_PROPS,
              variant: "error",
              message: msg,
            });
          });
        } else {
          const msg = `Telah terjadi pembaharuan terhadap harga dan jadwal yang telah kamu pilih. Silakan merefresh halaman ini`;
          enqueueSnackbar({
            ...DEFAULT_SNACKBAR_PROPS,
            variant: "error",
            message: msg,
          });
        }
      } else {
        errorLogger(error);
        enqueueSnackbar({
          ...DEFAULT_SNACKBAR_PROPS,
          variant: "error",
          message: "Terjadi kesalahan, mohon coba dalam beberapa saat lagi",
        });
      }
    }
  };

  const debouncedSubmit = useCallback(
    debounce(async () => {
      await handleSubmit();
    }, 300), // Adjust the debounce delay as needed
    [handleSubmit]
  );

  useEffect(() => {
    if (
      publicReducer.transaction.timestamp !== -1 &&
      dayjs().valueOf() - publicReducer.transaction.timestamp > 15 * 60 * 1000
    ) {
      dispatch(resetPublicTransaction());
    } else if (publicReducer.transaction.transactionId) {
      const navigateTo = `${RoutePath[ROUTE_NAME.DYNAMIC_INVOICE]}/${publicReducer.transaction.transactionId
        }`;
      navigate(navigateTo, { replace: true });
    }
  }, [
    navigate,
    publicReducer.transaction.transactionId,
    publicReducer.transaction.timestamp,
    dispatch,
  ]);

  useEffect(() => {
    if (
      productScheduleByIdQuery.isFetched &&
      !productScheduleByIdQuery.isLoading
    ) {
      const selectedDateKey = selectedDate.format("DD-MM-YYYY");
      const fetchedScheduleDetails = productScheduleData.scheduleDetails;

      if (!scheduleDetailsWithQuantities[selectedDateKey]) {
        const initializedScheduleDetails = fetchedScheduleDetails.map(
          (detail) => ({
            ...detail,
            qty: 0,
          })
        );

        setScheduleDetailsWithQuantities((prevState) => ({
          ...prevState,
          [selectedDateKey]: initializedScheduleDetails,
        }));
      }
    }
  }, [
    productScheduleByIdQuery.isFetched,
    productScheduleByIdQuery.isLoading,
    productScheduleData.scheduleDetails,
    scheduleDetailsWithQuantities,
    selectedDate,
  ]);

  useEffect(() => {
    // DISINI KENA KOSONG NAVIGATE BACK
    if (
      (!productDetailDataLoading && !productDetailData) ||
      isEmpty(productId)
    ) {
      navigate(-1);
    } else {
      if (productDetailData?.selectedDate) {
        const selectedDateDayjs = dayjs(
          productDetailData.selectedDate,
          "YYYY-MM-DD"
        );
        setSelectedDate(selectedDateDayjs);
      }
    }
  }, [productId, productDetailData, productDetailDataLoading]);

  // const updateUrlParams = useUpdateParams(navigate);

  // useEffect(() => {
  //   const params = {
  //     date: selectedDate ? selectedDate.format("YYYY-MM-DD") : null,
  //   };
  //   updateUrlParams(params);
  // }, [selectedDate, updateUrlParams]);

  const handleQuantityChange = (index: number, qty: number) => {
    const formattedDate = selectedDate.format("DD-MM-YYYY");
    setScheduleDetailsWithQuantities((prevState) => {
      const updatedDetails = prevState[formattedDate].map((detail, i) =>
        i === index ? { ...detail, qty } : detail
      );
      return {
        ...prevState,
        [formattedDate]: updatedDetails,
      };
    });
  };

  const totalItems = Object.values(scheduleDetailsWithQuantities).reduce(
    (sum, details) =>
      sum + details.reduce((detailSum, detail) => detailSum + detail.qty, 0),
    0
  );

  const priceDetail = getTransactionTax(
    Object.values(scheduleDetailsWithQuantities).reduce(
      (sum, details) =>
        sum +
        details.reduce(
          (detailSum, detail) => detailSum + detail.price * detail.qty,
          0
        ),
      0
    ),
    QRIS_TAX,
    false
  );
  const daysContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (daysContainerRef.current) {
      const selectedDayElement = daysContainerRef.current.querySelector(
        `[data-day="${selectedDate.format("YYYY-MM-DD")}"]`
      );
      if (selectedDayElement) {
        selectedDayElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [selectedDate]);

  const _renderLoadingSchedules = () => {
    return new Array(5).fill("").map((_, idx) => {
      return (
        <Skeleton
          width="100%"
          height="60px"
          key={idx}
          sx={{
            ":-webkit-transform": "none",
            transform: "none",
            mt: 1,
          }}
        />
      );
    });
  };
  const _renderSchedules = () => {
    if (productScheduleData.scheduleDetails.length === 0) {
      return <Typography>Tidak Ada jadwal hari ini</Typography>;
    }
    return productScheduleData.scheduleDetails.map((detail, index) => {
      const formattedDate = selectedDate.format("DD-MM-YYYY");
      const scheduleDetail: PublicScheduleDetailWithQty =
        scheduleDetailsWithQuantities[formattedDate]?.find(
          (d) => d.startTime === detail.startTime
        ) || { ...detail, qty: 0 };

      const startTime = dayjs(detail.startTime, "HH.mm")
        .set("date", selectedDate.date())
        .set("month", selectedDate.month())
        .set("year", selectedDate.year());
      const endTime = dayjs(detail.endTime, "HH.mm")
        .set("date", selectedDate.date())
        .set("month", selectedDate.month())
        .set("year", selectedDate.year());

      const currentTime = dayjs();
      const timeDifference = endTime.diff(currentTime, "minute");
      const timePassed = timeDifference < 0;
      const disabled = scheduleDetail.remainingQuota === 0 || timePassed;
      return (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            my: 2,
            pointerEvents: disabled ? "none" : "auto",
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <Box>
            <Typography fontSize={16} fontWeight={600}>
              {`${startTime.format("HH.mm")} - ${endTime.format("HH.mm")}`}
              <Typography
                fontSize={12}
                fontWeight={400}
                color={COLOR.danger400}
                variant="caption"
                ml="4px"
              >
                (Sisa {detail.remainingQuota})
              </Typography>
            </Typography>
            <Typography
              mt="8px"
              fontSize={16}
              fontWeight={600}
              color={COLOR.neutral500}
            >
              {formatCurrency(detail.price, "IDR")}
            </Typography>
          </Box>
          <QuantitySelector
            value={scheduleDetail.qty}
            onChange={(qty) => handleQuantityChange(index, qty)}
            min={0}
            max={detail.remainingQuota}
            maxDigits={2}
            // primaryColor={FONT_COLOR}
            disabled={disabled}
          />
        </Box>
      );
    });
  };

  return (
    <DynamicPageContainer backgroundColor={data.color}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          color: FONT_COLOR,
          backgroundColor: data.color,
        }}
      >
        <Box
          sx={{ width: "100%", aspectRatio: "16 / 9", position: "relative" }}
        >
          <img
            className="img-default"
            style={{ objectFit: "cover" }}
            src={checkoutData?.checkoutImg || "https://picsum.photos/200/300"}
            alt="product-img"
          />
          <OpacityButton
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              top: "20px",
              position: "absolute",
              left: "20px",
              backgroundColor: data.color,
              color: FONT_COLOR,
            }}
          >
            <FaCaretLeft size={16} style={{ paddingRight: "2px" }} />
          </OpacityButton>
          <OpacityButton
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              enqueueSnackbar({
                ...DEFAULT_SNACKBAR_PROPS,
                variant: "success",
                message: `Link siap dibagikan`,
                autoHideDuration: 1000,
              });
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              top: "20px",
              position: "absolute",
              right: "20px",
              backgroundColor: data.color,
              color: FONT_COLOR,
            }}
          >
            <IoMdShare size={16} style={{ paddingRight: "2px" }} />
          </OpacityButton>
        </Box>
        <Box px={3}>
          <Typography fontSize={24} fontWeight={600} py={2}>
            {checkoutData?.checkoutTitle || "-"}
          </Typography>
          <Box display="flex" flexDirection="column" flex={1} width="100%">
            <MarkdownRenderer
              markdown={checkoutData?.checkoutDesc
                .split("\n")
                .map(line => line.trimStart())
                .join("\n") || ""}
              customStyles={{
                h1: {
                  color: "blue",
                  fontWeight: "bold",
                  whiteSpace: "pre-wrap",
                },
                ul: {
                  color: "gray",
                  listStyleType: "disc",
                  whiteSpace: "pre-wrap",
                },
                li: { marginBottom: "10px", whiteSpace: "pre-wrap" },
                p: {
                  whiteSpace: "pre-wrap",
                },
                pre: {
                  whiteSpace: "pre-wrap",
                },
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            width="100%"
            alignItems="center"
            py={2}
          >
            <Box width="100%" textAlign="center" pb={2}>
              <Typography variant="caption">Hari yang dipilih</Typography>
              <Typography fontWeight={500} variant="h5">
                {selectedDate.format("DD MMM YYYY")}
              </Typography>
            </Box>
            <CalendarPicker
              baseColor={FONT_COLOR}
              primaryColor={data.color}
              minDate={dayjs()}
              maxDate={dayjs().add(
                checkoutData?.reservationPeriod === undefined
                  ? 60
                  : checkoutData?.reservationPeriod,
                "days"
              )}
              value={selectedDate}
              onChange={(date) => {
                if (date) {
                  setSelectedDate(date);
                }
              }}
            />
          </Box>
        </Box>

        <Box sx={{ px: 2, pb: 1, pt: 1, backgroundColor: "white" }}>
          {productScheduleByIdQuery.isLoading
            ? _renderLoadingSchedules()
            : _renderSchedules()}
        </Box>

        <Box
          component="form"
          sx={{ backgroundColor: "white" }}
          // onSubmit={() => {}}
          display="flex"
          flexDirection="column"
          py={2}
          gap={2}
          px={2}
        >
          <TextInput
            title="Nama Lengkap"
            required
            startEndorment={<UserIcon color={COLOR.neutral400} />}
            textInputProps={{
              placeholder: "cth: John Doe",
              value: paymentReceipent.name,
              onChange: handleOnChange("name"),
              onBlur: handleBlur("name"),
            }}
            helper={{
              color: COLOR.danger500,
              text: errors["name"],
            }}
            disabled={productScheduleData.scheduleDetails.length == 0}
          />
          <TextInput
            title="Alamat Email"
            required
            startEndorment={<MailIcon color={COLOR.neutral400} />}
            textInputProps={{
              placeholder: "cth: johndoe@email.com",
              value: paymentReceipent.email,
              onChange: handleOnChange("email"),
              onBlur: handleBlur("email"),
            }}
            helper={{
              color: COLOR.danger500,
              text: errors["email"],
            }}
            disabled={productScheduleData.scheduleDetails.length == 0}
          />
          <TextInput
            title="No Whatsapp"
            required={isWhatsAppRequired}
            startEndorment={
              <>
                <PhoneIcon color={COLOR.neutral400} />
                <Typography color={COLOR.neutral400} ml={"4px"}>
                  +62
                </Typography>
              </>
            }
            additionalPrefix={
              !isWhatsAppRequired && (
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color={COLOR.neutral400}
                >
                  (Optional)
                </Typography>
              )
            }
            textInputProps={{
              placeholder: "cth: 818239183912",
              value: formatPhoneNumber(paymentReceipent.phone),
              onChange: handleOnChange("phone"),
              onBlur: handleBlur("phone"),
              pattern: "[0-9]*",
              inputMode: "numeric",
            }}
            helper={{
              color: COLOR.danger500,
              text: errors["phone"],
            }}
            disabled={productScheduleData.scheduleDetails.length == 0}
          />
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} fontWeight={400}>
              Harga Produk{" "}
              <Typography
                variant="caption"
                fontSize={12}
                fontWeight={400}
                color={COLOR.danger400}
              >
                ({totalItems} item{totalItems !== 1 ? "s" : ""})
              </Typography>
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              {formatCurrency(priceDetail.amount, "IDR")}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={14} fontWeight={400}>
              Biaya Admin{" "}
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              {formatCurrency(priceDetail.adminFee, "IDR")}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={16} fontWeight={600}>
              Total{" "}
              <Typography
                variant="caption"
                fontSize={12}
                fontWeight={400}
                color={COLOR.danger400}
              >
                ({totalItems} item{totalItems !== 1 ? "s" : ""})
              </Typography>
            </Typography>
            <Typography fontSize={20} fontWeight={700}>
              {formatCurrency(priceDetail.total, "IDR")}
            </Typography>
          </Box>
          <CustomButton
            type="submit"
            fullWidth
            disabled={
              createNewTransactionMutation.isLoading ||
              productScheduleData.scheduleDetails.length === 0
            }
            onClick={(e) => {
              e.preventDefault(); // Prevents default action
              debouncedSubmit();
            }}
          >
            {checkoutData?.checkoutCTABtn || "Beli sekarang"}
          </CustomButton>
        </Box>
      </Box>
    </DynamicPageContainer>
  );
};

export default D2Checkout;
